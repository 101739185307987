<template>
  <div
    :class="$style.contextMenu"
    :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
  >
    <div :class="$style.link" @click="confirm(0)">
      Подтвердить все
    </div>
    <div
      v-if="!isTargetChecked"
      :class="$style.link"
      @click="confirm(notificationId)"
    >
      Подтвердить текущую
    </div>
    <router-link
      v-if="isTargetEvent"
      :class="$style.link"
      :to="{
        name: 'computers-computer-log-record',
        params: {
          computer: userId,
          log: log,
          record: eventId,
        },
        query: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      }"
      target="_blank"
    >
      Перейти к записи истории
    </router-link>
  </div>
</template>

<script>
import { checkRecord } from '@/api/methods/notifications';

export default {
  props: [
    'notificationId',
    'userId',
    'mouseX',
    'mouseY',
    'dateFrom',
    'dateTo',
    'eventId',
    'log',
    'isTargetChecked',
    'isTargetEvent',
  ],
  methods: {
    async confirm(event) {
      await checkRecord(event);
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" module>
.contextMenu {
  box-shadow: 4px 6px 14px rgba(165, 165, 165, 0.4);
  position: absolute;
  background-color: white;
  width: 244px;
  z-index: 30;
  padding: 10px;
}

.link {
  text-decoration: none;
  display: block;
  padding: 3px;
  color: #4b4b4b;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: $borderColor;
  }
}
</style>
