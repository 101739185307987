var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.contextMenu,style:({ left: _vm.mouseX + 'px', top: _vm.mouseY + 'px' })},[_c('div',{class:_vm.$style.link,on:{"click":function($event){return _vm.confirm(0)}}},[_vm._v(" Подтвердить все ")]),(!_vm.isTargetChecked)?_c('div',{class:_vm.$style.link,on:{"click":function($event){return _vm.confirm(_vm.notificationId)}}},[_vm._v(" Подтвердить текущую ")]):_vm._e(),(_vm.isTargetEvent)?_c('router-link',{class:_vm.$style.link,attrs:{"to":{
      name: 'computers-computer-log-record',
      params: {
        computer: _vm.userId,
        log: _vm.log,
        record: _vm.eventId,
      },
      query: {
        dateFrom: _vm.dateFrom,
        dateTo: _vm.dateTo,
      },
    },"target":"_blank"}},[_vm._v(" Перейти к записи истории ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }