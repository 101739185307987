import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

export function searchItems(str, dateFrom, dateTo, computers, logs) {
  return axios.post('/search', { str, dateFrom, dateTo, computers, logs }).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}

export function searchSolr(search, rowsNumber, repeatNumber, searchArray) {
  return axios.post('/search/solr', { search, rowsNumber, repeatNumber, searchArray }).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function searchFiles(md5, dateFrom, dateTo) {
  return axios.post('/search/files', { md5, dateFrom, dateTo }).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}
