var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((Object.entries(_vm.data)),function(ref,index){
var _obj;

var key = ref[0];
var value = ref[1];return _c('div',{key:index + key},[(_vm.ifGroupField(key))?_c('div',{class:_vm.$style.rowContainer,style:(_vm.indent(_vm.padding, true)),on:{"click":function($event){return _vm.toggle(value)}}},[_c('div',{class:[_vm.$style.expandButton, ( _obj = {}, _obj[_vm.$style.expanded] = value.expanded, _obj )]}),(_vm.columns[_vm.depth].field === 'details')?_c('div',{domProps:{"innerHTML":_vm._s(key)}}):_c('div',[_vm._v(" "+_vm._s(_vm.getDataField(key, _vm.columns[_vm.depth].field))+" ")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(value.expanded),expression:"value.expanded"}]},[(!value.array || !Array.isArray(value.array))?_c('div',[_c('grouped-table',{attrs:{"data":value,"columns":_vm.columns,"depth":_vm.depth + 1,"padding":_vm.padding + _vm.columns[_vm.depth].widthNum,"selected-row":_vm.selectedRow,"is-search":_vm.isSearch,"is-feed":_vm.isFeed,"is-files-search":_vm.isFilesSearch},on:{"changeSelectedRow":function (row) { return _vm.$emit('changeSelectedRow', row); },"openLogs":function (row) { return _vm.$emit('openLogs', row); },"showMenu":function (event, row) { return _vm.$emit('showMenu', event, row); }}})],1):_vm._e(),(value.array && Array.isArray(value.array))?_c('div',{style:(_vm.indent(_vm.padding + _vm.columns[_vm.depth].widthNum))},_vm._l((value.array),function(row,index){
var _obj, _obj$1;
return _c('div',{key:row.id.toString() + index.toString(),class:[
            ( _obj = {}, _obj[_vm.$style.active] = _vm.selectedRow && row.id === _vm.selectedRow, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.red] = !_vm.isSearch && !row.checkUser && !_vm.isFeed, _obj$1 ),
            _vm.$style.row ],on:{"click":function($event){return _vm.$emit('changeSelectedRow', row)},"dblclick":function($event){return _vm.$emit('openLogs', row)},"contextmenu":function($event){$event.preventDefault();return _vm.$emit('showMenu', $event, row)}}},_vm._l((_vm.columns.slice(_vm.depth + 1)),function(column){return _c('div',{key:column.field,style:({
              width: _vm.getWidth(column.widthNum),
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              padding: '0px 5px',
            })},[(_vm.isFilesSearch && column.field === 'path' && row.exists === 1
              )?_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/images/icons/navigation/confirm.svg")}}):_vm._e(),(_vm.isFilesSearch && column.field === 'path' && row.exists === 0
              )?_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/images/icons/navigation/cancel.svg")}}):_vm._e(),(column.field === 'details')?_c('div',[_c('log-details',{attrs:{"data":row}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.getDataField(row[column.field], column.field))+" ")])])}),0)}),0):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }