<template>
  <portal to="modal">
    <section
      :class="$style.wrapper"
      @click.stop="$emit('close')"
    >
      <div
        :class="modalClasses"
        @click.stop
      >
        <header :class="$style.header">
          {{ title }}
          <button
            :class="$style.buttonClose"
            @click="$emit('close')"
          >
            <img
              src="@/assets/images/icons/computer/screenshots/modal/close.svg"
              alt="Закрыть"
            >
          </button>
        </header>
        <article :class="$style.article">
          <slot />
        </article>
        <footer
          v-if="$slots.footer"
          :class="$style.footer"
        >
          <slot name="footer" />
        </footer>
      </div>
    </section>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue';
import ScrollLock from '@/utils/scroll-lock';

export default {
  components: {
    Portal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalClasses() {
      return [
        this.$style.modal,
        {
          [this.$style.modalFullscreen]: this.fullscreen,
        },
      ];
    },
  },
  mounted() {
    ScrollLock.enable();
  },
  beforeDestroy() {
    ScrollLock.disable();
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.modal {
  max-width: 600px;
  width: 100%;
  background: white;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.modalFullscreen {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.header {
  text-align: center;
  padding: 20px 30px;
  background: $white;
  box-shadow: rgba(218, 218, 218, 0.25);
  position: sticky;
  top: 0;
}

.buttonClose {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
}

.article {
  flex: 1;
  background: $light-gray;
}

.footer {
  padding: 12px;
  background: $white;
}
</style>
