<template>
  <modal
    header-text=""
    button-text="Ок"
    :class="$style.modal"
    @action="$emit('search')"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      Внимание! Выбор всех категорий может значительно увеличить время поиска!
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/search-modal.vue';

export default {
  components: { Modal },
};
</script>

<style lang="scss" module>
.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
