<template>
  <div>
    <div
      v-for="([key, value], index) in Object.entries(data)"
      :key="index + key"
    >
      <div
        v-if="ifGroupField(key)"
        :class="$style.rowContainer"
        :style="indent(padding, true)"
        @click="toggle(value)"
      >
        <div :class="[$style.expandButton, { [$style.expanded]: value.expanded }]" />

        <div
          v-if="columns[depth].field === 'details'"
          v-html="key"
        />
        <div v-else>
          {{ getDataField(key, columns[depth].field) }}
        </div>
      </div>
      <div v-show="value.expanded">
        <div v-if="!value.array || !Array.isArray(value.array)">
          <grouped-table
            :data="value"
            :columns="columns"
            :depth="depth + 1"
            :padding="padding + columns[depth].widthNum"
            :selected-row="selectedRow"
            :is-search="isSearch"
            :is-feed="isFeed"
            :is-files-search="isFilesSearch"
            @changeSelectedRow="(row) => $emit('changeSelectedRow', row)"
            @openLogs="(row) => $emit('openLogs', row)"
            @showMenu="(event, row) => $emit('showMenu', event, row)"
          />
        </div>
        <div
          v-if="value.array && Array.isArray(value.array)"
          :style="indent(padding + columns[depth].widthNum)"
        >
          <div
            v-for="(row, index) in value.array"
            :key="row.id.toString() + index.toString()"
            :class="[
              { [$style.active]: selectedRow && row.id === selectedRow },
              { [$style.red]: !isSearch && !row.checkUser && !isFeed },
              $style.row,
            ]"
            @click="$emit('changeSelectedRow', row)"
            @dblclick="$emit('openLogs', row)"
            @contextmenu.prevent="$emit('showMenu', $event, row)"
          >
            <div
              v-for="column in columns.slice(depth + 1)"
              :key="column.field"
              :style="{
                width: getWidth(column.widthNum),
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
                padding: '0px 5px',
              }"
            >
              <img
                v-if="isFilesSearch && column.field === 'path' && row.exists === 1
                "
                src="@/assets/images/icons/navigation/confirm.svg"
                :class="$style.icon"
              >
              <img
                v-if="isFilesSearch && column.field === 'path' && row.exists === 0
                "
                src="@/assets/images/icons/navigation/cancel.svg"
                :class="$style.icon"
              >

              <div v-if="column.field === 'details'">
                <log-details :data="row" />
              </div>
              <div v-else>
                {{ getDataField(row[column.field], column.field) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, getTime } from '@/helpers/format-date';
import LogDetails from '@/components/common/table/log-details.vue';

export default {
  name: 'GroupedTable',
  components: {
    LogDetails,
  },
  props: [
    'data',
    'columns',
    'depth',
    'padding',
    'selectedRow',
    'isSearch',
    'isFilesSearch',
    'isFeed',
  ],
  data: () => ({}),
  methods: {
    getWidth(width) {
      const max = 100
        - (this.padding
          + this.columns[this.depth].widthNum
          + 10 * (this.depth + 1));
      const resultWidth = (width * 100) / max;
      return `${resultWidth}%`;
    },
    getDataField(value, field) {
      if (field === 'date') {
        const date = formatDate(value);
        const time = getTime(value);
        return `${date} ${time}`;
      }
      return value;
    },
    ifGroupField(key) {
      if (key !== 'expanded' && typeof this.data[key] !== 'boolean') {
        return true;
      }
      return false;
    },
    toggle(value) {
      this.$set(value, 'expanded', !value.expanded);
    },
    indent(padding, top) {
      if (top) {
        if (padding === 0) {
          return {
            marginLeft: '5px',
            top: `${80 + 30 * this.depth}px`,
            zIndex: `${this.columns.length + 1 - this.depth}`,
          };
        }

        return {
          marginLeft: `${padding}%`,
          top: `${80 + 30 * this.depth}px`,
          zIndex: `${this.columns.length + 1 - this.depth}`,
        };
      }
      return {
        paddingLeft: `${padding}%`,
      };
    },
  },
};
</script>

<style lang="scss" module>
.expandButton {
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.rowContainer {
  display: flex;
  cursor: pointer;
  position: relative;
  border: 1px solid #dfdfdf;
  word-break: break-all;
  padding: 5px;
  font-size: 13px;
  align-content: center;
  margin: 5px;
  position: sticky;
  z-index: 3;
  top: 80px;
  background: white;
}

.row {
  display: flex;
  padding: 10px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }

  &.active {
    background: $light-gray;
  }

  &.red {
    background-color: #ffb0b0;

    &:hover {
      background-color: $red;
    }

    &.active {
      background: $red;
    }
  }
}

.icon {
  display: inline-flex;
  margin-right: 10px;
  width: 15px;
}
</style>
