const sourceDict = {
  1000: 'Неизвестно',
  1001: 'Usb',
  1002: 'Облачные диски',
  1003: 'Drobpox',
  1004: 'GoogleDrive',
  1005: 'YandexDisk',
  1006: 'CloudMailRu',
  1007: 'OneDrive',
  1008: 'Local directory',
  1009: 'Remote',
};

export const source = (val) => sourceDict[val];

const actionDict = {
  33: 'Копирование файла',
  34: 'Модификация файла',
};

export const action = (val) => actionDict[val];

const availableDict = {
  0: 'Файл копии наход на контролируемом ПК',
  1: 'Файл успешно скопирован на сервер',
  2: 'Файл не был скопирован, возможно удален',
};

export const available = (val) => availableDict[val];
