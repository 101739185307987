<template>
  <table :class="$style.description">
    <tbody>
      <tr v-for="(row, idx) in list" :key="idx">
        <th>{{ row[0] }}</th>
        <td v-html="valueWithHighlights(row)" />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    found: {
      type: Object,
      default: () => [],
    },
    searchStringLength: {
      type: Number,
    },
  },
  methods: {
    valueWithHighlights(row) {
      if (typeof row[1] !== 'string' || !this.found[row[2]]) return row[1];

      let result = '';
      let start = 0;

      for (let i = 0; i < this.found[row[2]].length; i++) {
        const index = this.found[row[2]][i];
        result += `${row[1].slice(start, index)}<span>`;
        result
            += `${row[1].slice(index, index + this.searchStringLength)}</span>`;
        start = index + this.searchStringLength;
      }
      result += row[1].slice(start);

      return result;
    },
  },
};
</script>

<style lang="scss" module>
.description {
  padding: 5px 0;
  border-collapse: separate;
  border-spacing: 20px 5px;

  th {
    text-align: left;
    font-weight: bold;
    font-size: 13px;
  }

  td {
    font-size: 12px;
    word-break: break-all;
  }

  span {
    background: $branchColor;
  }
}
</style>
