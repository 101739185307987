<template>
  <div :class="[
    $style.searchResultContainer,
    {
      [$style.notificationsResult]: !isSearch && !isFeed,
    },
  ]"
  >
    <div
      v-if="grouppedColumns.length === 0 && !isResultEmpty || isFeed"
      :class="$style.groupHint"
    >
      Передвиньте колонку в эту область для группировки
    </div>
    <draggable
      :class="$style.draggableInput"
      :list="grouppedColumns"
      :group="{
        name: 'columns',
      }"
      :move="onMove"
      @change="move"
    >
      <div
        v-for="(element, idx) in grouppedColumns"
        :key="element.name"
      >
        <div :class="$style.grouppedItem">
          <div :class="$style.grouppedColumnName">
            {{ element.name }}
          </div>
          <button
            type="button"
            :class="$style.btnClose"
            aria-label="Close modal"
            @click="removeAt(idx)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </draggable>
    <div
      v-if="isResultEmpty && !isFeed"
      :class="$style.emptyResult"
    >
      {{ emptyResultString }}
    </div>
    <table
      v-else
      :class="$style.table"
    >
      <thead :class="$style.head">
        <draggable
          :class="$style.draggableColumns"
          :list="reorderedColumns"
          :group="{
            name: 'columns',
            pull: 'clone',
            put: false,
          }"
          :sort="false"
          tag="tr"
          :move="onMove"
          @change="move"
        >
          <td
            v-for="column in reorderedColumns"
            :key="column.field"
            :class="$style.td"
            :style="{ width: column.width }"
            @dblclick="$emit('changeSort', column)"
          >
            <div :class="$style.tdInline">
              {{ column.name }}
            </div>
            <img
              src="@/assets/images/icons/table/sort-arrow.svg"
              :class="[
                $style.tdInline,
                $style.arrow,
                {
                  [$style.asc]: column.sort == 2,
                  [$style.hidden]: column.sort == 0,
                },
              ]"
              @click="$emit('changeSort', column)"
            >
            <div
              v-if="!isSearch && !isFeed"
              :class="$style.tdInline"
            >
              <img
                :id="'filterIcon' + column.field"
                src="@/assets/images/icons/navigation/filter.svg"
                :class="[$style.tdInline]"
                @click="$emit('filterClick', column)"
              >
              <div class="dropdown">
                <treeselect
                  :id="'columnFilter' + column.field"
                  v-model="selected[column.field]"
                  :multiple="true"
                  :options="column.filter"
                  :always-open="column.showFilter"
                  :normalizer="normalizer"
                  @input="filterGroupedData"
                />
              </div>
            </div>
          </td>
        </draggable>
      </thead>
    </table>
    <table
      v-if="grouppedColumns.length === 0"
      :class="$style.resultTable"
    >
      <tr
        v-for="(item, index) in sortedData"
        :key="item.id.toString() + index.toString()"
        :class="[
          { [$style.active]: selectedRow && item.id === selectedRow.id },
          { [$style.red]: !isSearch && !item.checkUser && !isFeed },
          $style.tr,
        ]"
        @click="selectRow(item)"
        @dblclick="openLogs(item)"
        @contextmenu.prevent="showMenu($event, item)"
      >
        <td
          v-for="column in reorderedColumns"
          :key="item.id + column.field"
          :class="$style.td"
          :style="{ width: column.width }"
        >
          <img
            v-if="isFilesSearch && column.field === 'path' && item.exists === 1"
            src="@/assets/images/icons/navigation/confirm.svg"
            :class="$style.icon"
          >
          <img
            v-if="isFilesSearch && column.field === 'path' && item.exists === 0"
            src="@/assets/images/icons/navigation/cancel.svg"
            :class="$style.icon"
          >

          <div v-if="column.field === 'date' && isFeed">
            <p
              v-if="item.isNew"
              :class="$style.new"
            >
              NEW
            </p>
            {{ getDataField(item[column.field], column.field) }}
          </div>

          <div v-else-if="column.field === 'details'">
            <log-details :data="item" />
          </div>
          <div v-else>
            {{ getDataField(item[column.field], column.field) }}
          </div>
        </td>
      </tr>
    </table>
    <div v-else>
      <grouped-table
        :data="sortedGroupData"
        :columns="reorderedColumns"
        :depth="0"
        :padding="0"
        :selected-row="selectedRowId"
        :is-search="isSearch"
        :is-feed="isFeed"
        :is-files-search="isFilesSearch"
        @changeSelectedRow="selectRow"
        @openLogs="openLogs"
        @showMenu="showMenu"
      />
    </div>
    <additional-list
      v-if="isSearch && !isFilesSearch && selectedRow"
      :list="getAdditionalList(selectedRow.logId, selectedRow)"
      :search="true"
      :found="foundSubstr"
      :search-string-length="searchString.length"
    />
    <additional-list
      v-if="!isSearch && !isFilesSearch && selectedRow && !isFeed"
      :list="additionalListNotifications"
    />
    <context-menu
      v-show="isMenuVisible"
      id="notificationsContextmenu"
      :notification-id="targetId"
      :user-id="targetUser"
      :mouse-x="mouseX"
      :mouse-y="mouseY"
      :date-from="dateRange.dateFrom"
      :date-to="dateRange.dateTo"
      :event-id="targetEvent"
      :log="selectedLog"
      :is-target-checked="isTargetChecked"
      :is-target-event="isTargetEvent"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import draggable from 'vuedraggable';
import Treeselect from '@riophae/vue-treeselect';
import GroupedTable from '@/components/common/table/groupedTable.vue';
import LogDetails from '@/components/common/table/log-details.vue';
import {
  formatDate,
  getTime,
  convertSecondsToDays,
} from '@/helpers/format-date';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import {
  messengerName,
  directionName,
} from '@/components/pages/computers/log/messengers/filters';
import { status } from '@/components/pages/computers/log/programs/utils';
import { action as instProgAction } from '@/components/pages/computers/log/installed-programs/utils';
import { action as filesAction } from '@/components/pages/computers/log/files/utils';
import { type } from '@/components/pages/computers/log/web-mail/utils';
import { type as clipboardType } from '@/components/pages/computers/log/clipboard/utils';
import { logTypes } from '@/assets/scripts/dictionaries';
import { getLogs } from '@/api/methods/computers';
import {
  source,
  action,
  available,
} from '@/components/pages/computers/log/shadow-copyings/utils';
import {
  driveType,
  action as drivesAction,
} from '@/components/pages/computers/log/external-drives/utils';

import { action as netFilesAction } from '@/components/pages/computers/log/network-files/utils';
import { action as hardwareAction } from '@/components/pages/computers/log/hardware-changes/utils';
import ContextMenu from '@/components/common/contextmenu/notifications-menu.vue';

export default {
  components: {
    draggable,
    GroupedTable,
    AdditionalList,
    Treeselect,
    ContextMenu,
    LogDetails,
  },
  props: [
    'isSearch',
    'tableData',
    'searchString',
    'emptyResultString',
    'columns',
    'isFiles',
    'isFilesSearch',
    'isFeed',
  ],
  data: () => ({
    targetId: null,
    isTargetChecked: true,
    isTargetEvent: false,
    dateRange: { dateFrom: new Date(), dateTo: new Date() },
    targetUser: null,
    targetEvent: null,
    isMenuVisible: false,
    mouseX: null,
    mouseY: null,
    selectedLog: null,
    notGroupedColumns: [],
    selected: [],
    grouppedColumns: [],
    groupResult: [],
    isResultEmpty: false,
    selectedRow: null,
    foundSubstr: {},
    rowAdditionalData: null,
    logIdsMapping: {
      1: 1,
      2: 3,
      3: 4,
      4: 5,
      6: 7,
      7: 10,
      10: 14,
      11: 8,
      13: 15,
      17: 19,
      18: 23,
      19: 23,
      20: 22,
      21: 20,
      22: 21,
      23: 6,
      26: 25,
    },
  }),
  computed: {
    filteredData() {
      let result = this.tableData.slice(0);
      Object.entries(this.selected).forEach(([key, value]) => {
        if (value.length > 0) {
          result = result.filter((item) => value.indexOf(item[key]) > -1);
        }
      });

      return result;
    },
    sortedData() {
      const calc = this.filteredData.slice(0).sort(this.compare);
      return calc;
    },
    sortedGroupData() {
      Object.keys(this.groupResult).forEach((key) => {
        this.sortNodes(this.groupResult[key]);
      });
      return this.groupResult;
    },
    additionalListNotifications() {
      if (this.rowAdditionalData) {
        const logTypeSlug = this.logIdsMapping[this.selectedRow.categoryId];
        return this.getAdditionalList(logTypeSlug, this.rowAdditionalData);
      }
      return [
        ['Причина', this.selectedRow.reasonString],
        ['Содержимое', this.selectedRow.descriptionOutput],
      ];
    },
    selectedRowId() {
      if (this.selectedRow) return this.selectedRow.id;
      return null;
    },
    reorderedColumns() {
      const result = [];
      this.grouppedColumns.forEach((col) => result.push(col));
      if (result.length === 0) return this.columns;
      if (result.length < this.columns.length) {
        this.columns.forEach((column) => {
          const found = this.grouppedColumns.find(
            (item) => item.field === column.field,
          );
          if (!found) result.push(column);
        });
      }
      return result;
    },
  },
  watch: {
    tableData(newArray, oldArray) {
      if (oldArray !== newArray) {
        //  this.grouppedColumns = [];
        //  this.groupResult = [];
        this.selectedRow = null;
        if (newArray.length > 0) this.isResultEmpty = false;
        else this.isResultEmpty = true;

        this.notGroupedColumns = this.columns.slice();

        if (this.grouppedColumns.length > 0) this.group();
        else if (this.isFilesSearch) {
          const userColumn = this.columns.find(
            (column) => column.field === 'username',
          );
          const typeColumn = this.columns.find(
            (column) => column.field === 'histtype_name',
          );
          this.grouppedColumns.push(userColumn);
          this.grouppedColumns.push(typeColumn);
          this.group();
        }
      }
    },
  },
  methods: {
    showMenu(event, item) {
      if (!this.isSearch && !this.isFeed) {
        if (item.checkTime && item.checkUser) {
          this.isTargetChecked = true;
          this.targetId = null;
        } else {
          this.isTargetChecked = false;
          this.targetId = item.id;
        }

        this.mouseX = event.clientX;
        this.mouseY = event.clientY;

        if (item.eventId && item.categoryId !== 28) {
          this.isTargetEvent = true;

          this.targetUser = item.compId;
          this.targetEvent = item.eventId;
          const logTypeSlug = this.logIdsMapping[item.categoryId];
          const targetLog = logTypes.find((log) => log.id === logTypeSlug);
          this.selectedLog = targetLog.slug;
          const dateFrom = new Date(item.eventTime);
          dateFrom.setHours(0);
          dateFrom.setMinutes(0);
          dateFrom.setSeconds(0);
          this.dateRange.dateFrom = dayjs(dateFrom).format('YYYY-MM-DD');
          const dateTo = new Date(item.eventTime);
          dateTo.setHours(23);
          dateTo.setMinutes(59);
          dateTo.setSeconds(59);
          this.dateRange.dateTo = dayjs(dateTo).format('YYYY-MM-DD');
        } else {
          this.isTargetEvent = false;
        }

        this.isMenuVisible = true;

        window.addEventListener('click', (e) => {
          this.isMenuVisible = false;
          window.removeEventListener('click', e);
        });
      }
    },
    getAdditionalList(category, data) {
      let { compname } = data;
      if (!this.isSearch) compname = data.username;

      if (this.isFiles) return [['Содержимое файла', data.content, 'content']];

      switch (category) {
        case 1:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ['Путь', data.path, 'path'],
            ['Текст', data.keys, 'keys'],
          ];
        case 2:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ['Путь', data.path, 'path'],
          ];
        case 3:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ['Путь', data.path, 'path'],
            ['Категория', data.category, 'category'],
            ['Программа', data.name, 'name'],
            ['Действие', status(data.status), 'status'],
          ];
        case 4:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Действие', filesAction(data.action), 'action'],
            ['Путь', data.path, 'path'],
            ['Размер', data.size, 'size'],
            ['MD5', data.crc, 'crc'],
            // ['Имя файла', data.exeName, 'exeName'],
          ];
        case 5:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ...(data.type === 0
              ? [['Содержимое буфера обмена', data.text, 'text']]
              : []),
            ['Тип данных', clipboardType(data.type), 'typeName'],
          ];
        case 6:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Принтер', data.printer, 'printer'],
            ['Документ', data.document, 'document'],
            ['Распечатанных страниц', data.pagesPrinted, 'pagesPrinted'],
            ['Копий', data.copies, 'copies'],
            ['Компьютер', data.computer, 'computer'],
            ['Стоимость', data.cost, 'cost'],
          ];
        case 7:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ['Действие', instProgAction(data.action, 'action')],
            ['Путь', data.path, 'path'],
          ];
        case 8:
          return [
            ['Действие', drivesAction(data.action)],
            ['Буква диска', data.driveChar],
            ['Метка диска', data.driveLabel],
            ['Тип диска', driveType(data.driveType)],
            ['Файловая система', data.filesystem],
            ['Серийный номер', data.serial],
            ['Имя пользователя', compname],
          ];
        case 10:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Заголовок окна', data.caption, 'caption'],
            ['Категория', data.category, 'category'],
            ['Ссылка', data.link, 'link'],
            ['Время на сайте', convertSecondsToDays(data.duration), 'duration'],
          ];
        case 14:
          return [
            ['Действие', action(data.action)],
            ['Имя файла', data.filename],
            ['Размер файла', data.filesize],
            ['Размещение файла', available(data.available)],
            ['Тип', source(data.source)],
            ['File MD5', data.crc],
            ['Имя пользователя', compname],
          ];
        case 15:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Дата отправки', data.dateSend, 'dateSend'],
            ['Тема письма', data.subject, 'subject'],
            ['От кого', data.from, 'from'],
            ['Кому', data.to, 'to'],
            ['Текст', data.text, 'text'],
          ];
        case 19:
          return [
            ['Имя пользователя', compname],
            ['Имя файла', data.filepath],
            ['Действие', netFilesAction(data.action)],
            ['Права доступа', data.fileaccess],
          ];
        case 20:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Дата отправки', data.dateSend, 'dateSend'],
            ['Тема письма', data.subject, 'subject'],
            ['От кого', data.from, 'from'],
            ['Кому', data.to, 'to'],
            ['Текст', data.text, 'text'],
            ['Тип сообщения', type(data.type, 'type')],
            ['Сервер почты', data.server, 'server'],
          ];
        case 21:
          return [
            ['Ссылка', data.url],
            ['Сервер', data.host],
            ['Имя пользователя', compname],
          ];
        case 22:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Собеседник', data.buddy, 'buddy'],
            ['Социальная сеть', data.socialDomain, 'socialDomain'],
            ['Текст', data.text, 'text'],
          ];
        case 23:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Мессенджер', messengerName(data.messenger), 'messenger'],
            ['Собеседник', data.interlocutor, 'interlocutor'],
            ['Тип сообщения', directionName(data.direction), 'direction'],
            ['Текст', data.text, 'text'],
            ['Имя в мессенджере', data.usernameMessenger, 'usernameMessenger'],
          ];
        case 25:
          return [
            ['Действие', hardwareAction(data.action)],
            ['Тип устройства', data.hardwareType],
            ['Описание', data.description],
          ];
        case 26:
          return [
            ['Имя пользователя', compname, 'compname'],
            ['Поисковик', data.engine, 'engine'],
            ['Текст запроса', data.query, 'query'],
          ];
        default:
          return [['Имя пользователя', compname]];
      }
    },
    filterGroupedData() {
      if (this.grouppedColumns.length > 0) {
        this.group();
      }
    },
    normalizer(node) {
      return {
        id: node,
        label: node,
      };
    },
    sortNodes(data) {
      if (data.array && Array.isArray(data.array)) {
        data.array.sort(this.compare);
      } else {
        Object.keys(data).forEach((key) => {
          this.sortNodes(data[key]);
        });
      }
    },
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;

      a = a[key];
      b = b[key];

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.columns[index].sort === 1) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    getIndicesOf(searchStr, str) {
      const searchStrLen = searchStr.length;
      if (searchStrLen === 0) {
        return [];
      }
      let startIndex = 0;
      let index;
      const indices = [];

      // eslint-disable-next-line
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
      return indices;
    },
    async selectRow(row) {
      if (this.isSearch) {
        if (!this.isFilesSearch) {
          this.selectedRow = null;
          const found = {};

          // eslint-disable-next-line
          for (const [key, value] of Object.entries(row)) {
            if (typeof value === 'string') {
              const indices = this.getIndicesOf(
                this.searchString.toLowerCase(),
                value.toLowerCase(),
              );

              if (indices.length > 0) {
                found[key] = indices;
              }
            }
          }

          this.foundSubstr = found;
        }
      } else {
        this.rowAdditionalData = null;
        if (row.eventId && row.categoryId !== 28) {
          const logTypeSlug = this.logIdsMapping[row.categoryId];
          if (logTypeSlug) {
            const log = logTypes.find((logItem) => logItem.id === logTypeSlug);
            this.promise = await getLogs(0, log.slug, row.eventId, '', '').then(
              (data) => {
                this.rowAdditionalData = data[0];
              },
            );
          }
        }
      }

      this.selectedRow = row;
    },
    changeRow(id) {
      const row = this.tableData.find((item) => item.id === id);
      this.selectedRow = row;
    },
    getDataField(value, field) {
      if (field === 'eventTime' || field === 'date') {
        const date = formatDate(value);
        const time = getTime(value);
        return `${date} ${time}`;
      }
      if (field === 'checkTime') {
        const date = new Date(value);
        if (date.getTime() === 0) return '';
        return value;
      }
      return value;
    },
    openLogs(item) {
      if (this.isSearch && !this.isFilesSearch) {
        const targetLog = logTypes.find((log) => log.id === item.logId);
        const routeData = this.$router.resolve({
          name: 'computers-computer-log-record',
          params: {
            computer: item.compId,
            log: targetLog.slug,
            record: item.id,
          },
          query: {
            dateFrom: dayjs(item.date).format('YYYY-MM-DD'),
            dateTo: dayjs(item.date).format('YYYY-MM-DD'),
          },
        });
        window.open(routeData.href, '_blank');
      }
    },
    move(evt) {
      let dragged = null;
      if (evt.added) dragged = evt.added.element.field;
      if (evt.moved) dragged = evt.moved.element.field;

      this.group();

      const indexOfObject = this.notGroupedColumns.findIndex(
        (object) => object.field === dragged,
      );

      if (indexOfObject > -1) this.notGroupedColumns.splice(indexOfObject, 1);
    },
    onMove(evt) {
      if (evt.from.nodeName === 'TR') {
        const exists = this.grouppedColumns.find(
          (col) => col.field === evt.draggedContext.element.field,
        );
        if (exists) return false;
        return true;
      }
      return true;
    },
    removeAt(idx) {
      const column = this.grouppedColumns[idx];
      this.grouppedColumns.splice(idx, 1);
      this.notGroupedColumns.push(column);
      if (this.grouppedColumns.length > 0) this.group();
    },
    groupRecursive(grouppedResult, columns) {
      if (columns.length > 0) {
        // eslint-disable-next-line
        for (const key of Object.keys(grouppedResult)) {
          if (
            grouppedResult[key].array
            && Array.isArray(grouppedResult[key].array)
          ) {
            const groupObj = {};

            const groupField = columns[0].field;

            for (let i = 0; i < grouppedResult[key].array.length; i++) {
              const fieldValue = grouppedResult[key].array[i][groupField];

              if (!groupObj[fieldValue]) {
                groupObj[fieldValue] = {
                  expanded: true,
                  array: [grouppedResult[key].array[i]],
                };
              } else {
                groupObj[fieldValue].array.push(grouppedResult[key].array[i]);
              }
            }
            groupObj.expanded = grouppedResult[key].expanded;
            grouppedResult[key] = groupObj;
          }

          this.groupRecursive(grouppedResult[key], columns.slice(1));
        }
      }
    },
    group() {
      const data = this.sortedData;
      const groupField = this.grouppedColumns[0].field;

      const grouppedResult = {};

      data.forEach((item) => {
        if (!grouppedResult[item[groupField]]) {
          grouppedResult[item[groupField]] = {
            expanded: true,
            array: [item],
          };
        } else {
          grouppedResult[item[groupField]].array.push(item);
        }
      });

      this.groupRecursive(grouppedResult, this.grouppedColumns.slice(1));
      this.groupResult = grouppedResult;
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.searchResultContainer {
  display: flex;
  margin: 10px;
  border: 1px solid #c4c4c4;
  overflow: auto;
  height: 60%;
  flex-direction: column;
  overflow-x: hidden;
}

.notificationsResult {
  height: 100%;
}

.emptyResult {
  margin: 30px;
  display: flex;
  justify-content: center;
}

.table {
  word-break: break-word;
  overflow: auto;
  padding: 10px;
  border-collapse: collapse;
  position: sticky;
  z-index: 20;
  top: 50px;
  background: white;
  height: 30px;
  font-weight: 500;
  cursor: pointer;
  margin: 5px;
}

.td {
  padding: 5px;
  letter-spacing: -0.08px;
  font-size: 13px;
  border-bottom: 1px solid $borderColor;
  height: 30px;
  max-width: 500px;
  word-break: break-word;
}

.draggableInput {
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  padding: 10px;
}

.grouppedItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 5px;
  border: 1px solid $borderColor;
  font-size: 13px;
  cursor: grab;
}

.btnClose {
  position: relative;
  top: 0;
  right: 0px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
  margin: 10px;
}

.grouppedColumnName {
  margin-left: 10%;
}

.groupHint {
  padding: 10px;
  font-size: 13px;
  color: #9a99a2;
  position: sticky;
  top: 0;
  z-index: 5;
  background: white;
  line-height: 30px;
}

.tr {
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }

  &.active {
    background: $light-gray;
  }

  &.red {
    background-color: #ffb0b0;

    &:hover {
      background-color: $red;
    }

    &.active {
      background: $red;
    }
  }
}

.resultTable {
  margin: 10px;
}

.tdInline {
  display: inline-flex;
}

.arrow {
  margin-top: 7px;
  margin: 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.icon {
  display: inline-flex;
  margin-right: 10px;
  width: 15px;
}
</style>

<style>
.sortable-ghost {
  border: 1px dashed grey;
  font-size: 0;
  overflow: hidden;
  margin: 5px;
}

.dropdown .vue-treeselect__control {
  display: none !important;
}

.dropdown .vue-treeselect__menu-container {
  width: 300px !important;
}

#columnFilterkeywords .vue-treeselect__menu {
  left: -300px;
}
</style>
