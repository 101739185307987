import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

export function getNotificationsHistory(dateFrom, dateTo) {
  return axios.post('/notifications', { dateFrom, dateTo }).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}

export function checkRecord(record) {
  return axios.post(`/notifications/check/?id=${record}`).then((response) => {
    if (response.data.success) {
      if (record === 0) { openToast('Записи успешно подтверждены', 'success'); } else {
        openToast('Запись успешно подтверждена', 'success');
      }
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}
