<template>
  <layout-navigation>
    <section :class="$style.page">
      <tabset
        class="searchTabs"
        :tabs="searchTabs"
        :opened="true"
        :hide-arrow="true"
        :tab-style="tabStyle"
        @tabClick="openNewSearchTab"
      />
      <div
        v-show="searchTabs[0].active"
        :class="$style.universalContainer"
      >
        <div
          v-if="isSolrActive"
          :class="$style.toggleContainer"
          @click="changeSearchType"
        >
          <div>Расширенный поиск</div>
          <toggle-button
            v-model="solrSearchActive"
            :class="$style.toggle"
            :value="solrSearchActive"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div :class="$style.mainContainer">
          <div :class="[$style.container, $style.searchStrContainer]">
            <input
              v-model="searchString"
              type="text"
              :class="$style.input"
              placeholder="Строка поиска"
            >
            <div
              v-if="isSolrActive && solrSearchActive"
              :class="$style.searchOptions"
            >
              <div :class="$style.radioContainer">
                <div
                  v-for="option in exactMatchOptions"
                  :key="option.name"
                  @click="changeExactMatchValue"
                >
                  <input
                    v-model="ifExactMatch"
                    type="radio"
                    :value="option.value"
                    :class="$style.radio"
                  >
                  <label :class="$style.radio">{{ option.name }}</label>
                </div>
              </div>
              <div :class="$style.radioContainer">
                <div
                  :class="$style.customCheckBoxContainer"
                  @click="allWordsSearch = !allWordsSearch"
                >
                  <input
                    v-model="allWordsSearch"
                    type="checkbox"
                    :class="$style.customCheckbox"
                  >
                  <label>
                    <check-mark v-if="allWordsSearch" />
                    <div :class="$style.textContainer">
                      Все слова должны быть в поиске
                    </div>
                  </label>
                </div>

                <div
                  :class="[
                    $style.customCheckBoxContainerDist,
                    $style.customCheckBoxContainer,
                  ]"
                  @click="checkWordsDistanse"
                >
                  <input
                    v-model="ifWordsDistanse"
                    type="checkbox"
                    :class="$style.customCheckbox"
                  >
                  <label>
                    <check-mark v-if="ifWordsDistanse" />
                    <div :class="[
                      $style.textContainer,
                      { [$style.disabled]: !allWordsSearch },
                    ]"
                    >
                      Расстояние между словами
                    </div>
                  </label>
                  <input
                    v-model.number="wordsDistanse"
                    type="number"
                    :class="$style.numberInput"
                    :disabled="!ifWordsDistanse || !allWordsSearch"
                    min="0"
                    @click.stop
                  >
                </div>
              </div>
              <div :class="$style.repeatContainer">
                <label> Повторяется более </label>
                <input
                  v-model.number="repeatNumber"
                  type="number"
                  :class="$style.repeatInput"
                  min="0"
                  @click.stop
                >
                <label> раз </label>
              </div>
            </div>
            <div
              v-if="isSolrActive && solrSearchActive"
              :class="$style.rowsNumContainer"
            >
              <label :class="$style.textContainer">
                Количество результатов поиска
              </label>
              <input
                v-model.number="rowsNumber"
                type="number"
                :class="$style.numberInput"
                min="0"
                @click.stop
              >
            </div>
          </div>
          <div :class="[$style.container, $style.logsMainContainer]">
            <div :class="$style.containerHeader">
              Где искать
            </div>
            <tabset
              v-if="isSolrActive && solrSearchActive"
              class="logsTabs"
              :tabs="tabs"
              :opened="true"
              :hide-arrow="true"
              :class="$style.tabs"
              :tab-style="tabStyle"
              @tabClick="openNewTab"
            />
            <div
              v-if="logs.length > 0 && (!solrSearchActive || !isSolrActive)"
              :class="$style.logsContainer"
            >
              <div
                :class="$style.customCheckBoxContainer"
                @click="checkAllLogs"
              >
                <input
                  v-model="allChecked"
                  type="checkbox"
                  :class="$style.customCheckbox"
                >
                <label>
                  <check-mark v-if="allChecked" />
                  <div :class="$style.textContainer">Выбрать все</div>
                </label>
              </div>
              <div
                v-for="log in logs"
                :key="log.id"
              >
                <div
                  :class="$style.customCheckBoxContainer"
                  @click="toggleCheckBox(log.id)"
                >
                  <input
                    v-model="log.checked"
                    type="checkbox"
                    :class="$style.customCheckbox"
                  >
                  <label>
                    <check-mark v-if="log.checked" />
                    <div :class="$style.textContainer">
                      {{ log.name }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="logs.length > 0 && isSolrActive && solrSearchActive"
              class="searchLogs"
            >
              <treeselect
                v-model="selectedLogs"
                :multiple="true"
                :options="logs"
                :clearable="true"
                :open-on-click="true"
                placeholder="Выбор категорий"
                value-consists-of="LEAF_PRIORITY"
                :default-expand-level="1"
                @select="checkLog"
                @deselect="uncheckLog"
              />
            </div>
            <div v-if="logs.length === 0">
              Нет доступных категорий поиска
            </div>

            <div
              v-if="isSolrActive && solrSearchActive && tabs[1].active"
              :class="[$style.universalContainer]"
            >
              <div
                v-for="(parameter, index) in fileParameters"
                :key="index"
                :class="[$style.fileParamsContainer]"
                @click="check(index)"
              >
                <div :class="[$style.label]">
                  {{ parameter.label }}
                </div>
                <select
                  v-model="parameter.value"
                  :class="$style.select"
                  :disabled="!parameter.checked"
                  @click.stop
                >
                  <option
                    v-for="option in fileOptions"
                    :key="option.id"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
                <div :class="$style.flex">
                  <input
                    v-model="parameter.input"
                    :class="$style.input"
                    :disabled="!parameter.checked"
                    @click.stop
                  >
                </div>
                <toggle-button
                  v-model="parameter.checked"
                  :value="parameter.checked"
                  :sync="true"
                  @click.native.prevent
                />
              </div>
              <div
                :class="[$style.fileParamsContainer]"
                @click="checkFileSize()"
              >
                <div :class="[$style.label]">
                  Размер файла, Кб
                </div>

                <div :class="$style.flex">
                  <div :class="[$style.label]">
                    От
                  </div>
                  <input
                    v-model.number="fileSize.from"
                    type="number"
                    :class="$style.input"
                    :disabled="!fileSize.checked"
                    min="0"
                    @click.stop
                  >
                  <div :class="[$style.label]">
                    До
                  </div>
                  <input
                    v-model.number="fileSize.to"
                    type="number"
                    :class="$style.input"
                    :disabled="!fileSize.checked"
                    :min="fileSize.from"
                    @click.stop
                  >
                </div>
                <toggle-button
                  v-model="fileSize.checked"
                  :value="fileSize.checked"
                  :sync="true"
                  @click.native.prevent
                />
              </div>
            </div>
          </div>
          <div :class="$style.container">
            <div :class="$style.containerHeader">
              Ограничение по времени
            </div>
            <div :class="$style.universalContainer">
              <div
                v-if="isSolrActive && solrSearchActive"
                :class="$style.radioContainer"
              >
                <div
                  v-for="option in timeFilter"
                  :key="option.name"
                  @click="changeDateRadioValue"
                >
                  <input
                    v-model="dateRangePicked"
                    type="radio"
                    :value="option.value"
                    :class="$style.radio"
                  >
                  <label :class="$style.radio">{{ option.name }}</label>
                </div>
              </div>
              <div v-show="dateRangePicked || !solrSearchActive || !isSolrActive">
                <div :class="$style.dateContainer">
                  <div :class="$style.dateLabel">
                    С:
                  </div>
                  <date-picker
                    v-model="dateFrom"
                    :class="$style.datePicker"
                  />
                  <date-picker
                    v-model="dateFrom"
                    :class="$style.datePicker"
                    type="time"
                  />
                </div>
                <div :class="$style.dateContainer">
                  <div :class="$style.dateLabel">
                    По:
                  </div>
                  <date-picker
                    v-model="dateTo"
                    :class="$style.datePicker"
                  />
                  <date-picker
                    v-model="dateTo"
                    :class="$style.datePicker"
                    type="time"
                  />
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.usersContainer">
            <dragTreeTable
              :isdraggable="false"
              :data="treeData"
            />
          </div>
        </div>
        <div :class="$style.buttonContainer">
          <button
            :class="$style.button"
            @click="search"
          >
            Поиск
          </button>
          <div
            v-if="state === reportEnum.tableReady && numFound > 0"
            :class="$style.numFound"
          >
            Всего найдено записей: {{ numFound }}
          </div>
        </div>
        <loader v-show="state === reportEnum.tableLoading" />
        <group-table
          v-show="state === reportEnum.tableReady"
          :table-data="searchResult"
          :is-search="true"
          :search-string="searchString"
          :columns="columns"
          :is-files="isSolrActive && solrSearchActive && tabs[1].active"
          empty-result-string="По Вашему запросу ничего не найдено"
          @changeSort="changeSort"
        />
      </div>
      <div
        v-show="searchTabs[1].active"
        :class="$style.universalContainer"
      >
        <div :class="$style.mainFilesContainer">
          <input
            v-model="searchStringFile"
            type="text"
            :class="$style.md5Input"
            placeholder="md5 файла"
          >

          <div>
            <label
              for="file-upload"
              class="custom-file-upload"
            >
              Указать образец файла с диска
            </label>
            <input
              id="file-upload"
              ref="doc"
              type="file"
              @change="readFile()"
            >
          </div>

          <div :class="$style.dateFiles">
            <div :class="$style.dateFilesContainer">
              <div :class="$style.dateLabel">
                С:
              </div>
              <date-picker
                v-model="dateFromFiles"
                :class="$style.datePicker"
              />
              <date-picker
                v-model="dateFromFiles"
                :class="$style.dateFilesPicker"
                type="time"
              />
            </div>
            <div :class="$style.dateFilesContainer">
              <div :class="$style.dateLabel">
                По:
              </div>
              <date-picker
                v-model="dateToFiles"
                :class="$style.datePicker"
              />
              <date-picker
                v-model="dateToFiles"
                :class="$style.dateFilesPicker"
                type="time"
              />
            </div>
          </div>
        </div>
        <div :class="$style.buttonContainer">
          <button
            :class="$style.button"
            @click="searchFiles"
          >
            Поиск
          </button>
        </div>
        <loader v-show="stateFiles === reportEnum.tableLoading" />
        <group-table
          v-show="stateFiles === reportEnum.tableReady"
          :table-data="searchResultFiles"
          :is-search="true"
          :is-files-search="true"
          :columns="columnsFiles"
          empty-result-string="По Вашему запросу ничего не найдено"
          @changeSort="changeSortFiles"
        />
      </div>
    </section>
    <warning-modal
      id="Warning"
      :class="$style.modal"
      @search="solrSearch"
      @close="cancelSearch"
    />
  </layout-navigation>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import DatePicker from 'vue2-datepicker';
import LayoutNavigation from '@/layouts/navigation.vue';
import CheckMark from '@/components/common/filter/check-mark.vue';
import { getComputers } from '@/api/methods/computers';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import { searchItems, searchSolr, searchFiles } from '@/api/methods/search';
import Loader from '@/components/common/blocks/loader.vue';
import GroupTable from '@/components/common/table/tableWithGrouping.vue';
import { getSearchType } from '@/api/methods/settings';
import { ToggleButton } from 'vue-js-toggle-button';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import WarningModal from '@/views/search/warning-modal.vue';
import Treeselect from '@riophae/vue-treeselect';
import { dateToUtc } from '@/helpers/format-date';

const md5 = require('md5');

Vue.use(VueToast);

export default {
  components: {
    LayoutNavigation,
    CheckMark,
    DatePicker,
    dragTreeTable,
    Loader,
    GroupTable,
    ToggleButton,
    Tabset,
    WarningModal,
    Treeselect,
  },
  data: () => ({
    file: null,
    numFound: 0,
    searchStringFile: '',
    repeatNumber: 0,
    rowsNumber: 50,
    searchArray: [],
    editedStr: '',
    fileSize: { checked: false, from: 0, to: 0 },
    fileParameters: [
      { value: ' AND ', input: '', label: 'Имя файла', checked: false },
      { value: ' AND ', input: '', label: 'Домен почты', checked: false },
      { value: ' AND ', input: '', label: 'MD5', checked: false },
    ],
    fileOptions: [
      { id: 1, label: 'И', value: ' AND ' },
      { id: 2, label: 'ИЛИ', value: ' OR ' },
      { id: 3, label: 'НЕ', value: ' AND NOT ' },
    ],
    selectedLogs: [],
    logQuery: '',
    compQuery: '',
    dateQuery: '',
    logicOptions: [
      { name: 'И', value: 2 },
      { name: 'ИЛИ', value: 1 },
      { name: 'НЕ', value: 0 },
    ],
    searchTabs: [
      {
        id: 0,
        title: 'Контентный поиск',
        active: true,
      },
      {
        id: 1,
        title: 'Карта движения файлов',
        active: false,
      },
    ],
    tabs: [
      {
        id: 0,
        title: 'Текст',
        active: true,
      },
      {
        id: 1,
        title: 'Содержимое файлов',
        active: false,
      },
    ],
    tabStyle: {
      fontSize: '14px',
    },
    allWordsSearch: false,
    wordsDistanse: 2,
    ifWordsDistanse: false,
    solrSearchActive: true,
    isSolrActive: false,
    specialSymbols: [
      '\\',
      '+',
      '-',
      '&',
      '|',
      '!',
      '(',
      ')',
      '?',
      '{',
      '}',
      '"',
      '[',
      ']',
      ':',
    ],
    timeFilter: [
      { name: 'Без ограничений', value: false },
      { name: 'За указанный промежуток', value: true },
    ],
    dateRangePicked: false,
    exactMatchOptions: [
      { name: 'Точное совпадение', value: true },
      { name: 'Толерантный поиск', value: false },
    ],
    ifExactMatch: true,
    searchLogsIndexes: [1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 22, 23, 26],
    logs: [],
    allChecked: false,
    searchString: '',
    dateFrom: new Date(),
    dateTo: new Date(),
    dateFromFiles: new Date(),
    dateToFiles: new Date(),
    checkedComputers: [],
    computersNumber: 0,
    searchResult: [],
    stateFiles: 2,
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    searchResultFiles: [],
    columnsFiles: [
      {
        name: 'Компьютер',
        width: '15%',
        widthNum: 15,
        field: 'username',
        sort: 1,
        index: 0,
      },
      {
        name: 'Направление',
        width: '10%',
        widthNum: 10,
        field: 'histtype_name',
        sort: 0,
        index: 1,
      },
      {
        name: 'Путь',
        width: '45%',
        widthNum: 45,
        field: 'path',
        sort: 0,
        index: 2,
      },
      {
        name: 'MD5',
        width: '20%',
        widthNum: 20,
        field: 'crc',
        sort: 0,
        index: 3,
      },
      {
        name: 'Время',
        width: '10%',
        widthNum: 10,
        field: 'datetime',
        sort: 0,
        index: 4,
      },
    ],
    columns: [
      {
        name: 'Категория',
        width: '20%',
        widthNum: 20,
        field: 'logName',
        sort: 1,
        index: 0,
      },
      {
        name: 'Пользователь',
        width: '20%',
        widthNum: 20,
        field: 'username',
        sort: 0,
        index: 1,
      },
      {
        name: 'Текст',
        width: '40%',
        widthNum: 40,
        field: 'text',
        sort: 0,
        index: 2,
      },
      {
        name: 'Дата',
        width: '20%',
        widthNum: 20,
        field: 'date',
        sort: 0,
        index: 3,
      },
    ],
    treeData: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: '',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Пользователь',
          field: 'title',
          align: 'left',
          flex: 3,
        },
        {
          title: 'Имя компьютера',
          field: 'user',
          align: 'left',
          flex: 2,
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
  }),
  computed: {
    allLogsChecked() {
      if (!this.solrSearchActive || !this.isSolrActive) {
        for (let i = 0; i < this.logs.length; i++) {
          if (!this.logs[i].checked) {
            return false;
          }
        }
        return true;
      }

      if (this.logs[0].children.length === this.selectedLogs.length) return true;
      return false;
    },
    logTypesFiltered() {
      return this.$store.getters['user/allowedTypes'].filter((log) => this.searchLogsIndexes.includes(log.id));
    },
  },
  async created() {
    const searchType = await getSearchType();
    if (searchType === '0') this.isSolrActive = false;
    else this.isSolrActive = true;

    this.getLogs();
    await this.fetch();
  },
  methods: {
    async readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();

      reader.onload = async (res) => {
        const arrayBuffer = res.target.result;
        const array = new Uint8Array(arrayBuffer);
        this.searchStringFile = md5(array);
      };
      reader.readAsArrayBuffer(this.file);
    },
    async searchFiles() {
      const result = await searchFiles(
        this.searchStringFile.toLowerCase(),
        this.dateFromFiles,
        this.dateToFiles,
      );
      if (result !== 'error') {
        this.searchResultFiles = result;
        this.stateFiles = this.reportEnum.tableReady;
      } else this.stateFiles = this.reportEnum.requestAwaiting;
    },
    check(index) {
      this.$set(
        this.fileParameters[index],
        'checked',
        !this.fileParameters[index].checked,
      );
    },
    checkFileSize() {
      this.$set(this.fileSize, 'checked', !this.fileSize.checked);
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;

      this.selectedLogs = [];
      this.getLogs();
      this.state = this.reportEnum.requestAwaiting;
    },
    openNewSearchTab(index) {
      this.searchTabs.forEach((item) => {
        item.active = false;
      });

      this.searchTabs[index].active = true;
    },
    checkWordsDistanse() {
      if (this.allWordsSearch) this.ifWordsDistanse = !this.ifWordsDistanse;
    },
    changeSearchType() {
      this.solrSearchActive = !this.solrSearchActive;
      this.getLogs();
    },
    getLogs() {
      this.allChecked = false;
      let logs = [];
      if (!this.isSolrActive || !this.solrSearchActive) {
        logs = this.logTypesFiltered;
        logs.forEach((log) => {
          log.checked = false;
        });
      } else {
        const activeTab = this.tabs.find((tab) => tab.active);
        if (activeTab.id === 0) {
          logs = [
            {
              id: 0,
              label: 'Выбрать все',
              children: [
                { id: 1, label: 'Клавиатура' },
                { id: 3, label: 'Приложения' },
                { id: 5, label: 'Буфер обмена' },
                { id: 10, label: 'Сайты' },
                { id: 15, label: 'Почта' },
                { id: 23, label: 'Мессенджеры' },
                { id: 6, label: 'Принтер' },
                { id: 4, label: 'Файлы' },
                { id: 22, label: 'Социальные сети' },
              ],
            },
          ];
        } else {
          logs = [
            {
              id: 0,
              label: 'Выбрать все',
              children: [
                { id: 6, label: 'Принтер' },
                { id: 14, label: 'Теневое копирование' },
                { id: 23, label: 'Мессенджеры' },
                { id: 15, label: 'Почта' },
                { id: 10, label: 'Интернет' },
                { id: 4, label: 'Документы на диске' },
              ],
            },
          ];
        }
      }

      this.logs = logs;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    changeSortFiles(column) {
      const { sort } = column;
      this.columnsFiles.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    checkAllLogs() {
      this.allChecked = !this.allChecked;
      if (this.allChecked) {
        for (let i = 0; i < this.logs.length; i++) {
          this.logs[i].checked = true;
        }
      } else {
        for (let i = 0; i < this.logs.length; i++) {
          this.logs[i].checked = false;
        }
      }
    },
    checkLog(node) {
      if (node.id === 0) {
        this.allChecked = true;
      } else if (this.allLogsChecked) this.allChecked = true;
      else this.allChecked = false;
    },
    uncheckLog(node) {
      if (node.id === 0) {
        this.allChecked = false;
      } else if (this.allLogsChecked) this.allChecked = true;
      else this.allChecked = false;
    },
    getCheckedComputers(checked) {
      checked.forEach((comp) => {
        if (comp.type === 'computer' && comp.checked) {
          this.checkedComputers.push(comp.id);
        } else if (comp.children && comp.children.length > 0) {
          this.getCheckedComputers(comp.children);
        }
      });
    },
    escapeSpecialSymbols(string) {
      let str = string.slice();
      for (let i = 0; i < this.specialSymbols.length; i++) {
        str = str.replaceAll(
          this.specialSymbols[i],
          `\\${this.specialSymbols[i]}`,
        );
      }

      return str;
    },
    addParamsQuery(params, fname) {
      let query = ')';
      if (params.includes('Имя файла')) {
        if (!fname) fname = 'fname';
        query += `${this.fileParameters[0].value}(${fname}:${this.fileParameters[0].input})`;
      }
      if (params.includes('Домен почты')) {
        query += `${this.fileParameters[1].value}(sendto:${this.fileParameters[1].input})`;
      }
      if (params.includes('MD5')) {
        query += `${this.fileParameters[2].value}(fileCRC:${this.fileParameters[2].input})`;
      }
      if (params.includes('size')) {
        query += ` AND (size:[${this.fileSize.from} TO ${this.fileSize.to}])`;
      }
      query += ')';
      return query;
    },
    formQueryString(logName, fields, params, fname) {
      let query = `(${logName}:[* TO *] AND (`;

      if (!this.allWordsSearch) {
        for (let i = 0; i < fields.length; i++) {
          query += `(${fields[i]}:(${this.editedStr}))`;
          if (i + 1 < fields.length) query += ' OR ';
          else if (this.tabs[1].active && params.length > 0) {
            query += this.addParamsQuery(params, fname);
          } else query += '))';
        }
      } else if (!this.ifWordsDistanse) {
        for (let i = 0; i < fields.length; i++) {
          query += '(';
          for (let j = 0; j < this.searchArray.length; j++) {
            query += `${fields[i]}:${this.searchArray[j]}`;
            if (j + 1 < this.searchArray.length) query += ' AND ';
            else query += ')';
          }
          if (i + 1 < fields.length) query += ' OR ';
          else if (this.tabs[1].active && params.length > 0) {
            query += this.addParamsQuery(params, fname);
          } else query += '))';
        }
      } else {
        for (let i = 0; i < fields.length; i++) {
          query += `(${fields[i]}:"${this.editedStr}"~${this.wordsDistanse})`;
          if (i + 1 < fields.length) query += ' OR ';
          else if (this.tabs[1].active && params.length > 0) {
            query += this.addParamsQuery(params, fname);
          } else query += '))';
        }
      }

      return query;
    },
    assembleLogString(logId) {
      if (this.tabs[0].active) {
        switch (logId) {
          case 1: {
            let fields = ['keys', 'caption'];
            if (this.exactMatchOptions[1].value) fields = ['keys_ru', 'caption_ru'];

            const query = this.formQueryString('id_keyl', fields);

            return query;
          }
          case 3: {
            let fields = ['path', 'caption'];
            if (this.exactMatchOptions[1].value) fields = ['path', 'caption_ru'];

            let query = this.formQueryString('id_app', fields);
            query += ' OR ';

            fields = ['progname', 'uninstpath'];
            query += this.formQueryString('id_uninst', fields);

            return query;
          }
          case 5: {
            let fields = ['text', 'caption'];
            if (this.exactMatchOptions[1].value) fields = ['text_ru', 'caption_ru'];

            const query = this.formQueryString('id_clboard', fields);
            return query;
          }
          case 10: {
            let fields = ['link', 'caption'];
            if (this.exactMatchOptions[1].value) fields = ['link', 'caption_ru'];

            let query = this.formQueryString('id_urls', fields);
            query += ' OR ';

            fields = ['url'];
            query += this.formQueryString('id_url_att', fields);

            return query;
          }
          case 15: {
            let fields = ['text', 'msubject', 'mfrom', 'sendto'];
            if (this.exactMatchOptions[1].value) fields = ['text_ru', 'msubject_ru', 'mfrom', 'sendto'];

            let query = this.formQueryString('id_hmail', fields);
            query += ' OR ';
            query += this.formQueryString('id_wmail', fields);

            return query;
          }
          case 23: {
            let fields = ['text', 'toname'];
            if (this.exactMatchOptions[1].value) fields = ['text_ru', 'toname'];

            let query = this.formQueryString('id_skype_txt', fields);
            query += ' OR ';

            fields = ['toname', 'filename'];
            query += this.formQueryString('id_skype_voice', fields);

            return query;
          }
          case 6: {
            const fields = ['docname'];

            const query = this.formQueryString('id_printer', fields);
            return query;
          }
          case 4: {
            let fields = ['path', 'fileCRC'];

            let query = this.formQueryString('id_filedir', fields);
            query += ' OR ';

            fields = ['fname'];
            query += this.formQueryString('id_rem_files', fields);
            query += ' OR ';

            fields = ['filename'];
            query += this.formQueryString('id_netfiles', fields);

            return query;
          }
          case 22: {
            let fields = ['text', 'host'];
            if (this.exactMatchOptions[1].value) fields = ['text_ru', 'host'];

            const query = this.formQueryString('id_soc_net', fields);

            return query;
          }
          default:
            return '';
        }
      } else {
        let fields = ['content'];
        if (this.exactMatchOptions[1].value) fields = ['content_ru'];

        switch (logId) {
          case 6: {
            const params = [];

            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);

            const query = this.formQueryString(
              'id_printer',
              fields,
              params,
              'docname',
            );

            return query;
          }
          case 14: {
            const params = [];
            if (this.fileSize.checked) params.push('size');
            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);

            const query = this.formQueryString('id_rem_files', fields, params);

            return query;
          }
          case 23: {
            fields.push('toname');

            const params = [];
            if (this.fileSize.checked) params.push('size');
            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);

            const query = this.formQueryString(
              'id_skype_voice',
              fields,
              params,
            );

            return query;
          }
          case 15: {
            const params = [];
            if (this.fileSize.checked) params.push('size');
            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);
            if (
              this.fileParameters[1].checked
              && this.fileParameters[1].input.length > 0
            ) params.push(this.fileParameters[1].label);

            const query = this.formQueryString('id_hmail', fields, params);

            return query;
          }
          case 10: {
            const params = [];
            if (this.fileSize.checked) params.push('size');
            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);
            if (
              this.fileParameters[1].checked
              && this.fileParameters[1].input.length > 0
            ) params.push(this.fileParameters[1].label);

            let query = this.formQueryString('id_wmail', fields, params);
            query += ' OR ';
            query += this.formQueryString('id_url_att', fields, params);

            return query;
          }
          case 4: {
            const params = [];
            if (this.fileSize.checked) params.push('size');
            if (
              this.fileParameters[0].checked
              && this.fileParameters[0].input.length > 0
            ) params.push(this.fileParameters[0].label);
            if (
              this.fileParameters[2].checked
              && this.fileParameters[2].input.length > 0
            ) params.push(this.fileParameters[2].label);

            const query = this.formQueryString('id_pc_files', fields, params);

            return query;
          }
          default:
            return '';
        }
      }
    },
    cancelSearch() {
      this.state = this.reportEnum.requestAwaiting;
      this.closeModal('Warning');
    },
    async solrSearch() {
      this.closeModal('Warning');
      this.state = this.reportEnum.tableLoading;

      const query = this.compQuery + this.dateQuery + this.logQuery;

      const result = await searchSolr(
        query,
        this.rowsNumber,
        this.repeatNumber,
        this.searchArray,
      );

      if (result !== 'error') {
        this.numFound = result.solrResult.length; // result.numFound;
        this.searchResult = result.solrResult;

        this.state = this.reportEnum.tableReady;
      } else this.state = this.reportEnum.requestAwaiting;
    },
    async search() {
      const checkedLogs = [];
      let logString = '';

      if (!this.solrSearchActive || !this.isSolrActive) {
        this.logs.forEach((log) => {
          if (log.checked) {
            checkedLogs.push(log.id);
          }
        });
      } else {
        this.editedStr = this.escapeSpecialSymbols(this.searchString);
        this.searchArray = this.editedStr.split(' ');

        logString = '(';
        for (let i = 0; i < this.selectedLogs.length; i++) {
          logString += this.assembleLogString(this.selectedLogs[i]);
          if (i + 1 < this.selectedLogs.length) logString += ' OR ';
        }
        logString += ')';
      }

      this.logQuery = logString;

      this.checkedComputers = [];
      this.getCheckedComputers(this.treeData.lists);
      let compString = '';
      if (this.checkedComputers.length === this.computersNumber) this.checkedComputers = [0];
      else if (this.isSolrActive && this.solrSearchActive) {
        compString = '(cid:';
        this.checkedComputers.forEach((id) => {
          if (compString.length === 5) compString += id;
          else compString += ` OR cid:${id}`;
        });
        compString += ') AND ';
      }
      this.compQuery = compString;

      let dateString = '';

      if (this.dateRangePicked) {
        dateString = `datetime:[${dateToUtc(this.dateFrom)} TO ${dateToUtc(
          this.dateTo,
        )}] AND `;
      }
      this.dateQuery = dateString;

      const from = this.dateFrom.getTime();
      const to = this.dateTo.getTime();

      if (
        this.searchString.length > 0
        && from <= to
        && this.checkedComputers.length > 0
        && (((!this.isSolrActive || !this.solrSearchActive)
          && checkedLogs.length > 0)
          || (this.isSolrActive
            && this.solrSearchActive
            && this.selectedLogs.length > 0))
      ) {
        let result = null;

        if (!this.isSolrActive || !this.solrSearchActive) {
          this.state = this.reportEnum.tableLoading;
          result = await searchItems(
            this.searchString,
            this.dateFrom,
            this.dateTo,
            this.checkedComputers,
            checkedLogs,
          );
        } else if (this.allLogsChecked) this.openModal('Warning');
        else await this.solrSearch();

        if (result !== 'error' && result) {
          this.searchResult = result.unGrouppedResult;
          this.state = this.reportEnum.tableReady;
        }
      } else if (this.searchString.length < 1) {
        Vue.$toast.open({
          message: 'Введите строку поиска',
          type: 'error',
        });
      } else if (
        ((!this.isSolrActive || !this.solrSearchActive)
          && checkedLogs.length < 1)
        || (this.isSolrActive
          && this.solrSearchActive
          && this.selectedLogs.length < 1)
      ) {
        Vue.$toast.open({
          message: 'Выберите категории, по которым производить поиск',
          type: 'error',
        });
      } else if (from > to) {
        Vue.$toast.open({
          message: 'Введите корректный временной интервал',
          type: 'error',
        });
      } else if (this.checkedComputers.length < 1) {
        Vue.$toast.open({
          message: 'Выберите компьютеры, по которым производить поиск',
          type: 'error',
        });
      }
    },
    setNodeAttributes(node) {
      node.ident = node.id + node.name;
      if (node.type === 'folder') {
        node.opened = true;
        node.open = true;
        node.title = node.name;
        node.isContainChildren = true;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        node.opened = false;
        node.dropDisabled = true;
        node.title = node.nameUser;
        node.user = node.name;
      }
    },
    async fetch() {
      return getComputers().then(({ tree, computers }) => {
        tree.forEach((item) => this.setNodeAttributes(item));
        this.treeData.lists = tree;
        this.computersNumber = computers.length;
      });
    },
    toggleCheckBox(logId) {
      const log = this.logs.find((item) => item.id === logId);
      this.$set(log, 'checked', !log.checked);
      if (!log.checked && this.allChecked) this.allChecked = false;
      if (log.checked && !this.allChecked) {
        if (this.allLogsChecked) this.allChecked = true;
      }
    },
    changeDateRadioValue() {
      this.dateRangePicked = !this.dateRangePicked;
    },
    changeExactMatchValue() {
      this.ifExactMatch = !this.ifExactMatch;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.universalContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.page {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.customCheckBoxContainerDist {
  display: flex;
}

.customCheckBoxContainer {
  &:hover {
    background: $light-gray;
  }

  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input:checked+label>svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }

  label:active::after {
    background-color: #fbfbfb;
  }

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }

  input:checked+label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

.textContainer {
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 21px;
  padding-left: 10px;
  vertical-align: super;
  width: 80%;
}

.input {
  width: 99%;
  min-height: 30px;
}

.select {
  min-height: 30px;
}

.container {
  width: 20%;
  margin: 10px;
  height: 90%;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.logsContainer {
  margin: 10px;
  height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.usersContainer {
  width: 35%;
  margin: 10px;
  height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.mainContainer {
  display: flex;
  margin: 10px;
  border: 1px solid #c4c4c4;
  height: 40%;
  overflow: visible;
  min-width: 1006px;
  min-height: 300px;
}

.mainFilesContainer {
  display: flex;
  margin: 10px;
  border: 1px solid #c4c4c4;
  padding: 10px;
  align-items: center;
}

.dateFiles {
  min-width: 270px;
  margin: 0px 20px;
}

.dateFilesContainer {
  display: flex;
  align-items: baseline;
}

.dateFilesPicker {
  margin: 5px;
}

.dateContainer {
  display: flex;
  align-items: baseline;

  @media (max-width: 1840px) {
    flex-direction: column;
  }
}

.datePicker {
  margin: 5px;

  @media (max-width: 1840px) {
    width: 80%;
  }
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 30px;
  width: 127px;
  background: #2985bf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numFound {
  font-size: 14px;
  margin: 10px;
  display: flex;
}

.buttonContainer {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.dateLabel {
  margin-right: 5px;
  font-size: 14px;
  width: 5%;
}

.containerHeader {
  margin: 5px 0px 15px 0px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.logsContainer {
  overflow: auto;
}

.radio {
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
}

.radioContainer {
  margin: 10px 5px;
}

.toggleContainer {
  display: flex;
  width: 250px;
  justify-content: space-evenly;
  margin: 30px 20px 20px 20px;
  cursor: pointer;
  font-size: 15px;
  align-items: center;
}

.numberInput {
  width: 20%;
  height: 30px;
  margin-right: 2px;
}

.disabled {
  color: $light-black;
}

.tabs {
  margin-bottom: 10px;
}

.modal {
  display: none;
  z-index: 30;
}

.fileParamsContainer {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: space-between;
  margin: 3px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.searchOptions {
  overflow: overlay;
  margin: 5px 0px;
}

.searchStrContainer {
  min-width: 230px;
  justify-content: space-between;
}

.logsMainContainer {
  min-width: 280px;
}

.rowsNumContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-overflow: ellipsis;
}

.repeatInput {
  width: 20%;
  height: 30px;
  margin: 7px;
}

.repeatContainer {
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 14px;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.md5Input {
  height: 30px;
  width: 30%;
  margin: 10px;
  min-width: 110px;
}
</style>

<style>
.searchTabs {
  padding: 0px !important;
}

.drag-tree-table {
  margin: 0;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.drag-tree-table-header {
  background: #f5f5f5;
  line-height: 20px;
}

.tree-row {
  line-height: 20px;
}

.searchLogs {
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
}

.searchLogs .vue-treeselect {
  margin-left: 2%;
}

.searchLogs .vue-treeselect__control {
  border-radius: 0px;
  width: 95%;
}

.searchLogs .vue-treeselect__menu {
  width: 95%;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.searchLogs .vue-treeselect__option {
  font-size: 14px;
}

.searchLogs .vue-treeselect__multi-value {
  margin: 3px 0px;
}

.searchLogs .vue-treeselect__multi-value-item-container {
  padding-top: 3px;
}

.searchLogs .iconFolder {
  display: inline-flex;
}

.searchLogs .vue-treeselect__checkbox--checked {
  background-color: #2985bf;
  border: 1px solid #2985bf;
}

.searchLogs .vue-treeselect__check-mark {
  margin: 0.5px;
}

input[type='file'] {
  display: none;
}

.custom-file-upload {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 36px;
  background: #2985bf;
  padding: 9px 12px;
  display: inline-block;
  margin: 5px;
  font-size: 14px;
  min-width: 226px;
}
</style>
