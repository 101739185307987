<template>
  <transition name="modal-fade">
    <div :class="$style.modalBackdrop" @click="$emit('close')">
      <div
        id="modal"
        :class="$style.modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop=""
      >
        <header id="modalTitle" :class="$style.modalHeader">
          <slot name="header-left" />
          <div :class="$style.headerText">
            {{ headerText }}
          </div>
          <slot name="header" />
          <button
            v-if="!hideCross"
            type="button"
            :class="$style.btnClose"
            aria-label="Close modal"
            @click="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </header>

        <section id="modalDescription" :class="$style.modalBody">
          <div :class="$style.inputBlockContainer">
            <slot />
          </div>
        </section>

        <footer
          v-if="!hideFooter"
          :class="[$style.modalFooter, { [$style.center]: hide }]"
        >
          <button
            :class="[
              $style.button,
              { [$style.none]: hide },
              { [$style.disabled]: notActive },
            ]"
            @click="$emit('action')"
          >
            {{ buttonText }}
          </button>
          <slot name="footer" />
          <button :class="[$style.button, $style.buttonGrey]" @click="close">
            Отмена
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: [
    'headerText',
    'buttonText',
    'hide',
    'notActive',
    'hideFooter',
    'hideCross',
  ],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 30%;
  overflow: hidden;
}

.modalHeader,
.modalFooter {
  padding: 15px;
  display: flex;
}

.modalHeader {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-evenly;
}

.headerText {
  margin: 0px 20px 0px 0px;
}

.modalFooter {
  border-top: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
}

.center {
  display: flex;
  justify-content: flex-end;
}
.modalBody {
  position: relative;
  padding: 20px 10px;
  font-size: 13px;
}

.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.button {
  height: 30px;
  cursor: pointer;
  width: 95px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.disabled {
  background: $branchColor;
  border: $branchColor;
  cursor: auto;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttonGrey {
  background: #a6a6a6;
  border: 2px solid #a6a6a6;
}

.none {
  display: none;
}
</style>
