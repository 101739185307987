<template>
  <div>
    <div
      v-for="fieldItem in logTypes[data.logId]"
      :key="fieldItem.field"
    >
      <img
        v-if="fieldItem.field === 'ssh'"
        :class="$style.ssh"
        :src="url"
        @click="openModal()"
      >
      <button
        v-else-if="fieldItem.field === 'file' && data.logId !== 5 ||
          data.logId === 5 && fieldItem.field === 'file' && data.type !== 'Текст' ||
          data.logId === 23 && data.attachment"
        :class="$style.saveFileButton"
        @click="saveFile()"
      >
        Сохранить файл
      </button>
      <div
        v-else
        :class="$style.detailsItem"
      >
        <div
          v-if="fieldItem.field !== 'file' && (data.logId !== 5 || fieldItem.field !== 'text' || data.type === 'Текст')"
          :class="$style.itemHeader"
        >
          {{ fieldItem.text }}:
        </div>
        <div
          v-if="fieldItem.field !== 'file' && (data.logId !== 5 || fieldItem.field !== 'text' || data.type === 'Текст')"
          :class="$style.itemContent"
        >
          {{ data[fieldItem.field] }}
        </div>
      </div>
    </div>
    <modal
      v-if="isShowModal"
      :title="data.caption"
      :fullscreen="true"
      @close="close"
    >
      <div :class="$style.content">
        <div :class="$style.imageWrapper">
          <img :src="url">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {
  getScreenshotLink, getClipboardFileLink, getClipboardFile, getMicRecordFile, getShadowCopyingFile,
  getMessengersAttachment, getPrinterDocumentLink, getWebcamsAndMicrophonesFile,
} from '@/api/methods/computers';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Modal from '@/components/common/modal/index.vue';

Vue.use(VueToast);

export default {
  name: 'LogDetails',
  components: {
    Modal,
  },
  props: [
    'data',
  ],
  data: () => ({
    logTypes: {
      1:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Текст',
            field: 'keys',
          },
          {
            text: 'Путь',
            field: 'path',
          },
        ],
      2:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Скриншот',
            field: 'ssh',
          },
        ],
      3:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Категория',
            field: 'category',
          },
          {
            text: 'Программа',
            field: 'name',
          },
          {
            text: 'Действие',
            field: 'status',
          },
        ],
      4:
        [
          {
            text: 'Путь',
            field: 'path',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Размер',
            field: 'size',
          },
          {
            text: 'MD5',
            field: 'crc',
          },
        ],
      5:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Тип данных',
            field: 'type',
          },
          {
            text: 'Содержимое буфера обмена',
            field: 'text',
          },
          {
            text: 'Скриншот',
            field: 'ssh',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      6:
        [
          {
            text: 'Принтер',
            field: 'printer',
          },
          {
            text: 'Документ',
            field: 'document',
          },
          {
            text: 'Распечатанных страниц',
            field: 'pagesPrinted',
          },
          {
            text: 'Копий',
            field: 'copies',
          },
          {
            text: 'Компьютер',
            field: 'computer',
          },
          {
            text: 'Стоимость',
            field: 'cost',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      7:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Путь',
            field: 'path',
          },
        ],
      8:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Буква диска',
            field: 'driveChar',
          },
          {
            text: 'Метка диска',
            field: 'driveLabel',
          },
          {
            text: 'Тип диска',
            field: 'driveType',
          },
          {
            text: 'Файловая система',
            field: 'filesystem',
          },
          {
            text: 'Серийный номер',
            field: 'serial',
          },
        ],
      10:
        [
          {
            text: 'Заголовок окна',
            field: 'caption',
          },
          {
            text: 'Категория',
            field: 'category',
          },
          {
            text: 'Ссылка',
            field: 'link',
          },
          {
            text: 'Время на сайте',
            field: 'duration',
          },
        ],
      11: [{
        text: 'Действие',
        field: 'action',
      }],
      14:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Имя файла',
            field: 'filename',
          },
          {
            text: 'Размер файла',
            field: 'filesize',
          },
          {
            text: 'Размещение файла',
            field: 'available',
          },
          {
            text: 'Тип',
            field: 'source',
          },
          {
            text: 'MD5',
            field: 'crc',
          },
          {
            text: 'file',
            field: 'file',
          },
        ],
      15:
        [
          {
            text: 'Дата отправки',
            field: 'dateSend',
          },
          {
            text: 'Тема письма',
            field: 'subject',
          },
          {
            text: 'От кого',
            field: 'from',
          },
          {
            text: 'Кому',
            field: 'to',
          },
          {
            text: 'Текст',
            field: 'text',
          },
        ],
      18:
        [
          {
            text: 'Компьютер',
            field: 'computerName',
          },
          {
            text: 'Действие',
            field: 'action',
          },
        ],
      19:
        [
          {
            text: 'Имя файла',
            field: 'filepath',
          },
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Права доступа',
            field: 'fileaccess',
          },
        ],
      20:
        [
          {
            text: 'Дата отправки',
            field: 'dateSend',
          },
          {
            text: 'Тема письма',
            field: 'subject',
          },
          {
            text: 'От кого',
            field: 'from',
          },
          {
            text: 'Кому',
            field: 'to',
          },
          {
            text: 'Текст',
            field: 'text',
          },
          {
            text: 'Тип сообщения',
            field: 'type',
          },
          {
            text: 'Сервер почты',
            field: 'server',
          },
        ],
      21:
        [
          {
            text: 'Ссылка',
            field: 'url',
          },
          {
            text: 'Сервер',
            field: 'host',
          },
        ],
      22:
        [
          {
            text: 'Собеседник',
            field: 'buddy',
          },
          {
            text: 'Социальная сеть',
            field: 'socialDomain',
          },
          {
            text: 'Текст',
            field: 'text',
          },
        ],
      23:
        [
          {
            text: 'Мессенджер',
            field: 'messenger',
          },
          {
            text: 'Собеседник',
            field: 'interlocutor',
          },
          {
            text: 'Тип сообщения',
            field: 'direction',
          },
          {
            text: 'Текст',
            field: 'text',
          },
          {
            text: 'Имя в мессенджере',
            field: 'usernameMessenger',
          },
        ],
      25:
        [
          {
            text: 'Действие',
            field: 'action',
          },
          {
            text: 'Тип устройства',
            field: 'hardwareType',
          },
          {
            text: 'Описание',
            field: 'description',
          },
        ],
      26:
        [
          {
            text: 'Поисковик',
            field: 'engine',
          },
          {
            text: 'Текст запроса',
            field: 'query',
          },
        ],
    },
    isShowModal: false,
  }),

  computed: {
    url() {
      if (this.data.logId === 2) {
        return getScreenshotLink(this.data.compId, this.data.path);
      }
      if (this.data.logId === 5 && this.data.type === 'Скриншот') {
        return getClipboardFileLink(this.data.compId, this.data.filepath);
      }
      return null;
    },
  },
  methods: {
    openModal() {
      this.isShowModal = true;
      this.$router.replace({
        query: {
          ...this.$route.query,
          modal: null,
        },
      });
    },
    close() {
      this.isShowModal = false;
      const query = { ...this.$route.query };
      delete query.modal;

      this.$router.replace({
        query,
      });
    },
    async saveFile() {
      let result = null;
      switch (this.data.logId) {
        case 5: {
          result = await getClipboardFile(this.data.compId, this.data.id);
          break;
        }
        case 6: {
          result = await getPrinterDocumentLink(this.data.compId, this.data.id);
          break;
        }
        case 14: {
          result = await getShadowCopyingFile(this.data.compId, this.data.id);
          break;
        }
        case 23: {
          result = await getMessengersAttachment(this.data.compId, this.data.id);
          break;
        }
        case 24: {
          result = await getWebcamsAndMicrophonesFile(this.data.compId, this.data.id);
          break;
        }
        case 27: {
          result = await getMicRecordFile(this.data.compId, this.data.id);
          break;
        }
        default: { break; }
      }
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.detailsItem {
  display: flex;
}

.itemHeader {
  margin: 5px;
  font-weight: 500;
  min-width: 100px;
}

.itemContent {
  margin: 5px;
}

.ssh {
  width: 70%;
  margin: 7px;
}

.saveFileButton {
  cursor: pointer;
  display: block;
  width: 130px;
  height: 28px;
  background: #2985BF;
  border: 2px solid #2985BF;
  border-radius: 2px;
  font: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  margin: 5px;
}

.content {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
