<template>
  <table :class="$style.description">
    <tbody>
      <tr
        v-for="(row, idx) in list"
        :key="idx"
      >
        <th>{{ row[0] }}</th>
        <td>{{ row[1] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" module>
.description {
  padding: 5px 0;
  border-collapse: separate;
  border-spacing: 20px 5px;

  th {
    text-align: left;
    font-weight: bold;
    font-size: 13px;
  }

  td {
    font-size: 12px;
    word-break: break-all;
  }
}
</style>
