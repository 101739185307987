const actionDict = {
  5: 'Переименование',
  6: 'Создание',
  7: 'Удаление',
  40: 'Изменение',
  41: 'Переименование. Старое имя',
  42: 'Переименование. Новое имя',
  48: 'Чтение файла',
  49: 'Запись в файл',
};

export const action = (val) => actionDict[val];
