const messengerNameDict = {
  0: 'Skype',
  1: 'Viber',
  2: 'WhatsApp',
  3: 'Telegram',
};

export const messengerName = (val) => messengerNameDict[val];

const directionNameDict = {
  31: 'Входящее',
  32: 'Исходящее',
};

export const directionName = (val) => directionNameDict[val];
