<template>
  <div :class="$style.wrapper">
    <button
      :class="$style.btnAccordion"
      @click="isOpened = !isOpened"
    >
      <span>Дополнительная информация</span>
      <span :class="{ [$style.caretClosed]: !isOpened }">
        <img src="@/assets/images/icons/computer/screenshots/additional-angle.svg">
      </span>
    </button>
    <div v-if="isOpened">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isOpened: true,
  }),
};
</script>

<style lang="scss" module>
.wrapper {
  position: sticky;
  bottom: 0;
  border: 1px solid $borderColor;
  background: $white;
  z-index: 2;
}

.btnAccordion {
  background: $light-gray;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.caretClosed {
  transform: rotate(180deg);
}
</style>
