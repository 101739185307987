const driveTypeDict = {
  14: 'DRIVE_REMOVABLE',
  15: 'DRIVE_FIXED',
  16: 'DRIVE_REMOTE',
  17: 'DRIVE_CDROM',
  18: 'DRIVE_RAMDISK',
  20: 'Unknown',
  21: 'None',
};

export const driveType = (key) => driveTypeDict[key];

const actionDict = {
  12: 'Подключен диск',
  13: 'Отключен диск',
};

export const action = (key) => actionDict[key];
