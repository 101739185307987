<template>
  <base-collapse>
    <search-list
      v-if="search"
      :list="list"
      :found="found"
      :search-string-length="searchStringLength"
    />
    <partial-list v-else :list="list" />
  </base-collapse>
</template>

<script>
import BaseCollapse from '../_base/collapse.vue';
import PartialList from '../_partials/list.vue';
import SearchList from '../_partials/searchList.vue';

export default {
  components: {
    BaseCollapse,
    PartialList,
    SearchList,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: false,
    },
    found: {
      type: Object,
      default: null,
    },
    searchStringLength: {
      type: Number,
    },
  },
};
</script>
